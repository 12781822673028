import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

import { ButtonLink } from '../components/ButtonLink'

import { ReactComponent as AssetTextureDotsSVG } from '../assets/texture-dots.svg'

export type PageBodyCirclePromoProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCirclePromo = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	imageAlt,
	textHTML,
	buttonHref,
	buttonText,
	image,
}: PageBodyCirclePromoProps): JSX.Element => {
	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-4xl"
			className="max-w-screen-xl mx-auto bg-white relative overflow-hidden"
		>
			<AssetTextureDotsSVG
				className={clsx(
					'absolute w-1/3 -left-1/3 lg:left-0 -top-1/2 transform rotate-30 text-yellow-50',
					'lg:-translate-y-1/4',
				)}
			/>
			<AssetTextureDotsSVG className="absolute w-1/3 transform -right-1/4 md:-right-1/4 -bottom-1/3 lg:bottom-0 rotate-30 text-yellow-50" />

			<div className="grid gap-12 md:grid-cols-2 md:items-center lg:gap-18">
				{image && (
					<GatsbyImage
						image={image}
						alt={imageAlt || ''}
						className="h-full rounded-full"
					/>
				)}

				<div className="flex flex-col items-center md:items-start">
					{textHTML && (
						<StyledHTMLContent
							html={textHTML}
							className="text-center md:text-left max-w-50ch"
							componentOverrides={{
								h1: (Comp) => (props) => (
									<Comp
										as="h2"
										{...props}
										className={clsx(props.className, 'text-red-50')}
									/>
								),
							}}
						/>
					)}

					{buttonHref && (
						<ButtonLink variant="tealLarge" href={buttonHref} className="mt-8">
							<span className="sr-only">
								Learn more by visiting {buttonHref}
							</span>
							{buttonText}
						</ButtonLink>
					)}
				</div>
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCirclePromoFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	buttonText: data.primary?.button_text?.text,
	buttonHref: data.primary?.button_link?.url,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyCirclePromo on PrismicPageDataBodyCirclePromo {
		primary {
			image {
				alt
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
			text {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
				target
			}
		}
	}
`

export default PageBodyCirclePromo
