import clsx from 'clsx'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { Icon } from './Icon'

type Props = {
	url?: string | null
	posterImage?: IGatsbyImageData
	posterImageAlt?: string | null
	className?: string
	autoplay?: boolean
} & (
	| {
			isPlaying: boolean
			setPlaying: React.Dispatch<React.SetStateAction<boolean>>
	  }
	| {
			isPlaying?: undefined
			setPlaying?: undefined
	  }
)

export const VideoPlayer = ({
	url,
	posterImage,
	posterImageAlt,
	isPlaying: __isPlaying,
	setPlaying: __setPlaying,
	className,
	autoplay = false,
}: Props) => {
	const [_isPlaying, _setPlaying] = React.useState(false)

	const isPlaying = __isPlaying ?? _isPlaying
	const setPlaying = __setPlaying ?? _setPlaying

	const play = () => setPlaying(true)
	const stop = () => setPlaying(false)

	const videoId = url ? new URL(url).searchParams.get('v') : undefined

	return (
		<div className={clsx('relative', className)}>
			<div>
				{url && (
					<ReactPlayer
						url={url}
						playing={isPlaying}
						controls={true}
						onPlay={play}
						onPause={stop}
						onEnded={stop}
						width="100%"
						height="100%"
						style={{
							position: 'absolute',
							visibility: isPlaying ? 'visible' : 'hidden',
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						}}
						muted={autoplay}
						config={{
							youtube: {
								playerVars: {
									autoplay: autoplay ? 1 : 0,
									controls: autoplay ? 0 : 1,
									loop: autoplay ? 1 : 0,
									playlist: autoplay ? videoId : undefined,
								},
							},
						}}
					/>
				)}

				{posterImage && (
					<GatsbyImage
						image={posterImage}
						alt={posterImageAlt ?? ''}
						className={clsx(
							'h-full w-full transition absolute inset-0',
							isPlaying && 'opacity-0 pointer-events-none',
						)}
					/>
				)}

				{url && !autoplay && (
					<button
						onClick={play}
						className={clsx(
							'block absolute inset-0 transition w-full group bg-transparent',
							isPlaying
								? 'opacity-0 pointer-events-none'
								: 'opacity-100 pointer-events-auto',
						)}
					>
						<span className="sr-only">Play video</span>

						<div
							className={clsx(
								'py-4 bg-teal-40 md:py-6 px-7 md:px-14 bg-opacity-75 group-hover:bg-opacity-100 group-focus:bg-opacity-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition',
								isPlaying && 'opacity-0',
							)}
						>
							<Icon name="play" className="w-6 text-white md:w-8" />
						</div>
					</button>
				)}
			</div>
		</div>
	)
}
