import * as React from 'react'
import clsx from 'clsx'

import { Link, LinkProps } from './Link'

export type AnchorProps = LinkProps & {
	colorClassName?: string | null
	focusColorClassName?: string | null
	hoverColorClassName?: string | null
}

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
	(
		{
			className,
			colorClassName = 'text-teal-40 contrast-more:text-teal-10 contrast-more:underline',
			focusColorClassName = 'focus:text-red-50',
			hoverColorClassName = 'hover:text-red-50',
			...props
		},
		ref,
	): JSX.Element => (
		<Link
			ref={ref}
			className={clsx(
				'transition',
				colorClassName,
				focusColorClassName,
				hoverColorClassName,
				className,
			)}
			{...props}
		/>
	),
)
