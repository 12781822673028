import * as React from 'react'
import { graphql } from 'gatsby'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
	StaticImage,
} from 'gatsby-plugin-image'
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon'

export type PageBodyLLTextProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLLText = ({
	textHTML,
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyLLTextProps) => {
	const hasChildren = React.Children.count(children) > 0

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-screen-lg"
			ptClassName="pt-12 md:pt-17 lg:pt-22"
			pbClassName="pb-12 md:pb-17 lg:pb-22"
			className="relative mx-auto overflow-hidden bg-repeat-y bg-w-full white text-green-20 max-w-screen-xl bg-pattern-paper"
		>
			<div className="absolute top-0 left-0 hidden md:block">
				<StaticImage
					src="../../static/images/texture-grass.png"
					alt=""
					className="w-17rem transform -rotate-12 -translate-x-1/3 -translate-y-1/4"
					width={270}
					formats={['avif', 'webp']}
					quality={50}
					placeholder="none"
				/>
			</div>

			<div className="absolute top-0 right-0 hidden md:block">
				<StaticImage
					src="../../static/images/texture-grass.png"
					alt=""
					className="w-17rem transform rotate-45 translate-x-1/3 -translate-y-1/4"
					width={270}
					formats={['avif', 'webp']}
					quality={50}
					placeholder="none"
				/>
			</div>

			<div className="absolute bottom-0 left-0 hidden md:block">
				<StaticImage
					src="../../static/images/texture-grass.png"
					alt=""
					className="w-17rem transform -rotate-135 -translate-x-1/3 translate-y-1/4"
					width={270}
					formats={['avif', 'webp']}
					quality={50}
					placeholder="none"
				/>
			</div>

			<div className="absolute bottom-0 right-0 hidden md:block">
				<StaticImage
					src="../../static/images/texture-grass.png"
					alt=""
					className="w-17rem transform rotate-135 translate-x-1/3 translate-y-1/4"
					width={270}
					formats={['avif', 'webp']}
					quality={50}
					placeholder="none"
				/>
			</div>

			<div className="relative grid gap-8 lg:gap-16">
				{textHTML && (
					<HTMLContent
						html={textHTML}
						componentOverrides={{
							h1: (Comp) => (props) =>
								<Comp className="text-orange-60" {...props} />,
							a: (Comp) => (props) =>
								(
									<Comp
										colorClassName="text-orange-60"
										hoverColorClassName="hover:text-red-30"
										focusColorClassName="focus:text-red-30"
										{...props}
									/>
								),
							span:
								(Comp) =>
								({ class: labelName, ...props }) =>
									(
										<Comp
											className={clsx(
												labelName === 'Orange' && 'text-orange-60',
											)}
											{...props}
										/>
									),
						}}
						className="w-full mx-auto text-center max-w-screen-md"
					/>
				)}

				{hasChildren && (
					<ul className="flex flex-col items-center justify-center -mt-6 -ml-10 md:flex-wrap md:flex-row">
						{children}
					</ul>
				)}
			</div>
		</BoundedBox>
	)
}

type ImageProps = {
	image?: IGatsbyImageData
	imageAlt?: string
}

const Image = ({ image, imageAlt }: ImageProps) => {
	if (!image) return null

	return (
		<Dialog.Root>
			<li className="w-full pt-6 pl-10 max-w-24rem md:max-w-none md:w-4/12">
				<Dialog.Trigger className="block w-full">
					<span className="sr-only">View image in lightbox</span>
					<GatsbyImage
						image={image}
						alt={imageAlt || ''}
						className="border-8 border-white shadow-lg "
					/>
				</Dialog.Trigger>
			</li>

			<Dialog.Overlay className="bg-black/60 fixed inset-0 z-30" />
			<Dialog.Content className="fixed top-1/2 left-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 z-30 p-6 min-w-[20rem]">
				<GatsbyImage
					image={image}
					alt={imageAlt ?? ''}
					className="border-8 border-white shadow-lg "
				/>
				<Dialog.Close
					className="text-red-50 absolute right-8 top-8 md:top-10 md:right-10"
					type="button"
				>
					<span className="sr-only">Close image lightbox.</span>
					<XCircleIcon className="w-5 h-5" />
				</Dialog.Close>
			</Dialog.Content>
		</Dialog.Root>
	)
}

PageBodyLLText.Image = Image

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLLTextFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
	children: data.items?.map((item) => (
		<PageBodyLLText.Image
			key={item?.image?.url}
			image={getImage(item?.image)}
			imageAlt={item?.image?.alt ?? undefined}
		/>
	)) as React.ReactNode,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export const fragment = graphql`
	fragment PageBodyLLText on PrismicPageDataBodyLLText {
		primary {
			text {
				text
				html
			}
		}
		items {
			image {
				url
				alt
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
		}
	}
`

export default PageBodyLLText
