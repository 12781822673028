import * as Dialog from '@radix-ui/react-dialog'
import * as React from 'react'
import { Text } from '../Text'
import { NewsletterDialogContent } from './Content'

export type NewsletterDialog = {
	close: () => void
	open: () => void
}

export const NewsletterDialog = React.forwardRef<NewsletterDialog>(
	(_props, ref) => {
		const [open, setOpen] = React.useState(false)

		const closeDialog = React.useCallback(() => setOpen(false), [])
		const openDialog = React.useCallback(() => setOpen(true), [])

		React.useImperativeHandle(
			ref,
			() => ({ close: closeDialog, open: openDialog }),
			[closeDialog],
		)

		return (
			<Dialog.Root open={open} onOpenChange={setOpen}>
				<div className="absolute bottom-0 right-0 translate-y-full hidden lg:block">
					<Dialog.Trigger className="px-5 py-2 text-white shadow bg-teal-40 hover:bg-teal-20 focus:bg-teal-20 transition contrast-more:bg-teal-30 block">
						<Text
							variant="sans-10"
							as="span"
							className="font-bold tracking-widest text-center uppercase block"
						>
							Connect With Us
						</Text>
						<span className="sr-only">Open Mailing list dialog form.</span>
					</Dialog.Trigger>

					<noscript>
						<a
							href="/mailing-list-signup"
							className="px-5 py-2 text-white shadow bg-teal-40 hover:bg-teal-20 focus:bg-teal-20 transition contrast-more:bg-teal-30 block"
						>
							<Text
								variant="sans-10"
								as="span"
								className="font-bold tracking-widest text-center uppercase block"
							>
								Connect With Us
							</Text>
						</a>
					</noscript>
				</div>

				<Dialog.Portal>
					<Dialog.Overlay className="fixed bg-black/60 inset-0 z-30" />
					<Dialog.Content className="z-30 w-full mx-auto outline-none md:my-12 max-w-screen-sm fixed inset-0">
						<Dialog.Title className="sr-only">Connect with us</Dialog.Title>
						<Dialog.Description className="sr-only">
							Sign up for the L&L Newsletter
						</Dialog.Description>
						<NewsletterDialogContent closeDialog={closeDialog} />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
		)
	},
)
