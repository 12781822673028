import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { isInternal } from '@walltowall/helpers'

export type LinkProps = Omit<React.ComponentPropsWithoutRef<'a'>, 'as'>

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, ...props }, ref) => {
		if (!href) return <a ref={ref} {...props} />

		const internal = isInternal(href)
		if (!internal) {
			return (
				<a href={href} ref={ref} target="_blank" rel="noreferrer" {...props} />
			)
		}

		return <GatsbyLink to={href} ref={ref as any} {...props} />
	},
)
