import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import clsx from 'clsx'

import { Text } from './Text'

const defaultElement = 'button'

const variants = {
	redSmall: {
		backgroundColorClassName: 'bg-red-50 contrast-more:bg-red-30',
		borderBottomColorClassName: 'border-red-30',
		colorClassName: 'text-yellow-50',
		textVariant: 'sans-14',
		paddingClassName: 'py-3 px-4',
		minWidthClassName: 'min-w-8rem',
	},
	redLarge: {
		backgroundColorClassName: 'bg-red-50 contrast-more:bg-red-30',
		borderBottomColorClassName: 'border-red-30',
		colorClassName: 'text-yellow-50',
		textVariant: 'sans-17',
		paddingClassName: 'py-4 px-5',
		minWidthClassName: 'min-w-10rem',
	},
	yellowSmall: {
		backgroundColorClassName: 'bg-yellow-50',
		borderBottomColorClassName: 'border-yellow-40',
		colorClassName: 'text-red-50 contrast-more:text-red-30',
		textVariant: 'sans-14',
		paddingClassName: 'py-3 px-4',
		minWidthClassName: 'min-w-8rem',
	},
	yellowLarge: {
		backgroundColorClassName: 'bg-yellow-50',
		borderBottomColorClassName: 'border-yellow-40',
		colorClassName: 'text-red-50 contrast-more:text-red-30',
		textVariant: 'sans-17',
		paddingClassName: 'py-4 px-5',
		minWidthClassName: 'min-w-10rem',
	},
	tealSmall: {
		backgroundColorClassName: 'bg-teal-40 contrast-more:bg-teal-20',
		borderBottomColorClassName: 'border-teal-20',
		colorClassName: 'text-yellow-50 contrast-more:text-yellow-60',
		textVariant: 'sans-14',
		paddingClassName: 'py-3 px-4',
		minWidthClassName: 'min-w-8rem',
	},
	tealLarge: {
		backgroundColorClassName: 'bg-teal-40 contrast-more:bg-teal-20',
		borderBottomColorClassName: 'border-teal-20',
		colorClassName: 'text-yellow-50 contrast-more:text-yellow-60',
		textVariant: 'sans-17',
		paddingClassName: 'py-4 px-5',
		minWidthClassName: 'min-w-10rem',
	},
	beigeSmall: {
		backgroundColorClassName: 'bg-beige-80',
		borderBottomColorClassName: 'border-[#c49968]',
		colorClassName: 'text-red-50',
		textVariant: 'sans-14',
		paddingClassName: 'py-3 px-4',
		minWidthClassName: 'min-w-8rem',
	},
} as const

type ButtonOwnProps = {
	variant?: keyof typeof variants
	colorClassName?: string
	backgroundColorClassName?: string
	borderBottomColorClassName?: string
	paddingClassName?: string
	minWidthClassName?: string
	children?: React.ReactNode
}

export type ButtonProps<T extends React.ElementType = typeof defaultElement> =
	PolymorphicPropsWithoutRef<ButtonOwnProps, T>

export const Button = <T extends React.ElementType = typeof defaultElement>({
	as,
	variant: variantName = 'redLarge',
	colorClassName,
	backgroundColorClassName,
	borderBottomColorClassName,
	paddingClassName,
	minWidthClassName,
	className,
	children,
	disabled,
	...restProps
}: ButtonProps<T>) => {
	const Element: React.ElementType = as ?? defaultElement
	const variant = variants[variantName]

	return (
		<Element
			{...restProps}
			className={clsx(
				'inline-flex border-b-2 justify-center items-center',
				colorClassName ?? variant.colorClassName,
				backgroundColorClassName ?? variant.backgroundColorClassName,
				borderBottomColorClassName ?? variant.borderBottomColorClassName,
				paddingClassName ?? variant.paddingClassName,
				minWidthClassName ?? variant.minWidthClassName,
				disabled && 'opacity-50',
				className,
			)}
		>
			<Text
				variant={variant.textVariant}
				as="span"
				className="font-extrabold tracking-wider text-center uppercase block"
			>
				{children}
			</Text>
		</Element>
	)
}
