export const SkipNav = () => {
	return (
		<a
			href="#main"
			className="bg-white pointer-events-none absolute left-4 top-4 z-50 rounded-md p-4 text-black font-bold opacity-0 shadow focus:pointer-events-auto focus:opacity-100"
		>
			Skip to Content
		</a>
	)
}
