import * as React from 'react'
import { useSiteSettings } from '../hooks/useSiteSettings'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { Notification } from '../components/Notification'
import { ReactComponent as AssetLogoSVG } from '../assets/logo.svg'
import { MobileNavigation } from '../components/MobileNavigation'
import { DesktopNavigation } from '../components/DesktopNavigation'
import { NewsletterDialog } from '../components/NewsletterDialog'

const BG_IMG_URL =
	'https://images.prismic.io/hawaiianbarbecue/b9c18e41-f9fb-44e2-8cf4-cb4069c4c9fa_texture-header.png?auto=compress,format'

export type PageBodyHeaderProps = ReturnType<typeof mapDataToProps> & {
	notificationIsActive?: boolean
	notificationTextHTML?: string | null
} & PageTemplateEnhancerProps

const PageBodyHeader = ({ location }: PageBodyHeaderProps) => {
	const siteSettings = useSiteSettings()
	const rNewsletterDialg = React.useRef<NewsletterDialog>(null)

	return (
		<header className="relative z-20">
			<BoundedBox
				ptClassName="pt-3 lg:pt-5"
				pbClassName="pb-3 lg:pb-5"
				innerMaxWidthClassName="max-w-screen-xl"
				className="relative z-10 text-white bg-cover shadow-md bg-red-30"
				style={{ backgroundImage: `url('${BG_IMG_URL}')` }}
			>
				<div className="flex gap-8 justify-between">
					<Link href="/" className="self-end">
						<span className="sr-only">{siteSettings.siteName}</span>
						<AssetLogoSVG className="block -mb-6 -ml-2 xl:ml-10 w-17 lg:-mb-10 lg:w-23" />
					</Link>

					<MobileNavigation
						openNewsletterDialog={() => rNewsletterDialg.current?.open()}
						location={location}
					/>
					<DesktopNavigation location={location} />
				</div>
			</BoundedBox>

			<NewsletterDialog ref={rNewsletterDialg} />
			<div id="main" />
			<Notification />
		</header>
	)
}

export const mapDataToProps = ({ meta }: MapDataToPropsArgs) => ({
	location: meta?.location,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export default PageBodyHeader
