import * as Menu from '@radix-ui/react-navigation-menu'
import React from 'react'
import { Text } from '../Text'
import { Anchor } from '../Anchor'
import { Icon } from '../Icon'
import clsx from 'clsx'

type Props = {
	name?: string | null
	href: string
	subMenu?: Array<{ name?: string | null; href: string }>
}

export const MobileNavItem = ({ name, href, subMenu = [] }: Props) => {
	const hasSubMenu = subMenu.length > 0

	return (
		<Menu.Item className="py-3">
			<div className="items-center justify-center grid gap-4 grid-flow-col">
				<div className={clsx(hasSubMenu && 'ml-7', 'flex gap-3 items-center')}>
					<Text
						variant="sans-18"
						className="font-extrabold tracking-wider uppercase"
					>
						<Menu.Link asChild>
							<Anchor
								href={href}
								colorClassName="text-red-50"
								hoverColorClassName="hover:text-teal-40"
								focusColorClassName="focus:text-teal-40"
								className="p-3 -m-3 outline-none"
							>
								{name}
							</Anchor>
						</Menu.Link>
					</Text>

					{hasSubMenu && (
						<Menu.Trigger className="text-yellow-40 hover:text-teal-40 focus:text-teal-40 group/trigger">
							<span className="sr-only">Toggle {name} sub-menu</span>
							<Icon
								name="chevronDown"
								className="w-4 group-data-[state=open]/trigger:rotate-180"
							/>
						</Menu.Trigger>
					)}
				</div>
			</div>

			{hasSubMenu && (
				<Menu.Content className="py-2 mt-3 bg-yellow-40">
					<Menu.Sub>
						<Menu.List>
							{subMenu.map((i) => (
								<Menu.Item
									key={i.name}
									className="items-center justify-center py-3 grid"
								>
									<Text variant="sans-16" className="font-bold">
										<Menu.Link asChild>
											<Anchor
												href={i.href}
												colorClassName="text-white"
												hoverColorClassName="hover:text-red-30"
												focusColorClassName="focus:text-red-30"
												className="outline-none"
											>
												{i.name}
											</Anchor>
										</Menu.Link>
									</Text>
								</Menu.Item>
							))}
						</Menu.List>
					</Menu.Sub>
				</Menu.Content>
			)}
		</Menu.Item>
	)
}
