import { graphql } from 'gatsby'
import { BoundedBox } from '../components/BoundedBox'
import { PageTemplateEnhancerProps } from '../templates/page'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import ReactPlayer from 'react-player'
import React from 'react'
import { Icon } from '../components/Icon'
import clsx from 'clsx'
import * as styles from './PageBodyCarousel.module.css'
import { Link } from '../components/Link'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'

export type PageBodyCarousel = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCarousel = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	media,
}: PageBodyCarousel) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
		Autoplay({
			playOnInit: true,
			delay: 10000,
			stopOnInteraction: true,
			stopOnMouseEnter: true,
		}),
	])
	const [activeIdx, setActiveIdx] = React.useState(0)

	React.useEffect(() => {
		const handleSelect = () => {
			setActiveIdx(emblaApi?.selectedScrollSnap() ?? 0)
		}

		emblaApi?.on('select', handleSelect)

		return () => {
			emblaApi?.off('select', handleSelect)
		}
	}, [emblaApi])

	const previousSlide = () => {
		if (!emblaApi) return

		emblaApi.scrollPrev()
	}

	const nextSlide = () => {
		if (!emblaApi) return

		emblaApi.scrollNext()
	}

	const goToSlide = (idx: number) => {
		if (!emblaApi) return

		emblaApi.scrollTo(idx)
	}

	const activeMedia = media.at(activeIdx)
	const hasAdditionalText = activeMedia?.heading || activeMedia?.text

	return (
		<>
			<BoundedBox
				as="section"
				id={id}
				variant="fullBleed"
				nextSharesBg={nextSharesBg}
				nextOverhangs={nextOverhangs}
				previousOverhangs={previousOverhangs}
				innerMaxWidthClassName="max-w-none"
				pbClassName="pb-0"
				ptClassName="pt-0"
				className="w-full relative max-w-screen-xl mx-auto"
			>
				<div className="overflow-hidden" ref={emblaRef}>
					<div className="flex">
						{media.map((item, idx) => {
							const WrapperElement = item.href ? Link : 'div'

							return (
								<WrapperElement
									href={item.href!}
									className="flex-[0_0_100%] min-w-0 max-h-[34rem] lg:max-h-[48rem]"
									aria-label={`Learn more at: ${item.href}`}
									key={idx}
								>
									{item.videoUrl ? (
										<div className={styles.iframeWrapper}>
											<ReactPlayer
												url={item.videoUrl}
												playing={true}
												controls={false}
												loop={true}
												width="100%"
												height="100%"
												muted={true}
											/>
										</div>
									) : (
										<div
											className="h-full w-full aspect-[9/16] lg:aspect-[16/9] bg-gray-60"
											style={{
												backgroundColor: item.image
													? item.image.backgroundColor
													: undefined,
											}}
										>
											{item.image && (
												<GatsbyImage
													image={item.image}
													alt={item.imageAlt ?? ''}
													objectFit={item.fitImage ? 'contain' : 'cover'}
													className="h-full w-full"
												/>
											)}
										</div>
									)}
								</WrapperElement>
							)
						})}
					</div>
				</div>

				<button
					className="py-5 px-4 md:flex items-center justify-center rounded-full bg-white absolute left-10 top-1/2 -translate-y-1/2 hidden"
					onClick={previousSlide}
				>
					<Icon name="chevronDown" className="rotate-90 w-5 text-gray-10" />
					<span className="sr-only">Previous Slide</span>
				</button>

				<button
					className="py-5 px-4 md:flex hidden items-center justify-center rounded-full bg-white absolute right-10 top-1/2 -translate-y-1/2 "
					onClick={nextSlide}
				>
					<Icon
						name="chevronDown"
						className="rotate-[270deg] w-5 text-gray-10"
					/>
					<span className="sr-only">Next Slide</span>
				</button>

				{hasAdditionalText && (
					<div className="bg-gradient-to-t from-black h-[250px] bottom-0 inset-x-0 absolute pointer-events-none" />
				)}

				<div className="flex gap-1 bg-black absolute bottom-0 inset-x-0 h-2">
					{media.map((_media, idx) => (
						<button
							key={idx}
							className={clsx(
								idx === activeIdx ? 'bg-white' : 'bg-gray-60',
								'hover:bg-white flex-1 transition duration-200',
							)}
							onClick={() => {
								goToSlide(idx)
							}}
						>
							<span className="sr-only">See slide: {idx + 1}</span>
						</button>
					))}
				</div>

				{hasAdditionalText && (
					<div className="absolute bottom-10 inset-x-0 max-w-[36rem] px-4 text-white text-center mx-auto">
						{activeMedia.heading && (
							<Text variant="sans-24-35" as="h2" className="font-bold">
								{activeMedia.heading}
							</Text>
						)}

						{activeMedia.text && (
							<Text as="p" variant="sans-18-24" className="mt-5 font-medium">
								{media.at(activeIdx)?.text}
							</Text>
						)}

						{activeMedia.href && (
							<ButtonLink
								href={activeMedia.href}
								variant="beigeSmall"
								className="mt-7"
							>
								Learn More
							</ButtonLink>
						)}
					</div>
				)}
			</BoundedBox>
			{/* <noscript></noscript> */}
		</>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<Queries.PageBodyCarouselFragment>) => ({
	media: data.items?.map((item) => ({
		image: getImage(item?.image),
		imageAlt: item?.image?.alt,
		fitImage: item?.fit_image || false,
		href: item.link?.url,
		videoUrl: item.video_url?.embed_url,
		heading: item?.heading.text,
		text: item?.text.text,
	})),
})

export const mapDataToContext = () => ({
	bg: 'bg-yellow-50',
	overhangsBottom: false,
})

export const fragment = graphql`
	fragment PageBodyCarousel on PrismicPageDataBodyCarousel {
		items {
			image {
				alt
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
			fit_image

			video_url {
				embed_url
			}

			link {
				url
			}

			heading {
				text
			}

			text {
				text
			}
		}
	}
`

export default PageBodyCarousel
