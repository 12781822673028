import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { Link } from '../components/Link'

export type PageBodyLLContentProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLLContent = ({
	introTextHTML,
	disclaimerTextHTML,
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyLLContentProps) => {
	const hasChildren = React.Children.count(children) > 0

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-70rem"
			className="max-w-screen-xl mx-auto text-white bg-orange-60"
		>
			<div className="grid gap-10 md:gap-12">
				{introTextHTML && (
					<HTMLContent
						html={introTextHTML}
						componentOverrides={{
							h1: (Comp) => (props) => (
								<Comp className="text-green-70" {...props} />
							),
						}}
						className="text-center"
					/>
				)}
				{hasChildren && (
					<ul className="flex flex-wrap items-start justify-center -mt-10 -ml-8 lg:-ml-10">
						{children}
					</ul>
				)}
				{disclaimerTextHTML && (
					<HTMLContent
						html={disclaimerTextHTML}
						componentOverrides={{
							p: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
							li: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
							a: (Comp) => (props) => (
								<Comp
									colorClassName="text-green-70"
									hoverColorClassName="hover:text-yellow-50"
									focusColorClassName="focus:text-yellow-50"
									{...props}
								/>
							),
						}}
						className="text-center"
					/>
				)}
			</div>
		</BoundedBox>
	)
}

type ItemProps = {
	descriptionHTML?: string | null
	buttonText?: string | null
	buttonHref?: string | null
	buttonType?: 'Text' | 'Spotify'
}

const Item = ({
	descriptionHTML,
	buttonText = 'Learn more',
	buttonHref,
	buttonType = 'Text',
}: ItemProps) => (
	<li className="pt-10 pl-8 lg:pl-10 md:w-4/12">
		<div className="grid gap-6 justify-items-center">
			{descriptionHTML && (
				<HTMLContent
					html={descriptionHTML}
					componentOverrides={{
						h1: (Comp) => (props) => <Comp variant="sans-24" {...props} />,
						p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
					}}
					className="text-center max-w-24rem md:max-w-none"
				/>
			)}

			{buttonHref &&
				(buttonType === 'Spotify' ? (
					<Link href={buttonHref}>
						<StaticImage
							src="../../static/images/spotify-button.png"
							width={300}
							alt="Listen on Spotify"
							className="block w-8rem"
							quality={75}
							formats={['avif', 'webp']}
						/>
					</Link>
				) : (
					<ButtonLink variant="redSmall" href={buttonHref}>
						<span className="sr-only">Learn more by visiting {buttonHref}</span>
						{buttonText}
					</ButtonLink>
				))}
		</div>
	</li>
)

PageBodyLLContent.Item = Item

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLLContentFragment,
	typeof mapDataToContext
>) => ({
	introTextHTML: data.primary?.intro_text?.html,
	disclaimerTextHTML: data.primary?.disclaimer_text?.html,
	children: data.items?.map((item) => (
		<Item
			key={item?.button_link?.url}
			descriptionHTML={item?.content_description?.html}
			buttonText={item?.button_text?.text}
			buttonHref={item?.button_link?.url}
			buttonType={item?.button_type as 'Text' | 'Spotify'}
		/>
	)) as React.ReactNode,
})

export const mapDataToContext = () => ({
	bg: 'bg-orange-60',
})

export const fragment = graphql`
	fragment PageBodyLLContent on PrismicPageDataBodyLLContent {
		primary {
			intro_text {
				text
				html
			}
			disclaimer_text {
				text
				html
			}
		}
		items {
			content_description {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
			}
			button_type
		}
	}
`

export default PageBodyLLContent
