import { useNotificationMessage } from '../hooks/useNotificationMessage'
import { BoundedBox } from './BoundedBox'
import { HTMLContent } from './HTMLContent'
import { Icon } from './Icon'

export const Notification = () => {
	const notification = useNotificationMessage()

	if (!notification.isActive || !notification.textHTML) return null

	return (
		<BoundedBox
			innerMaxWidthClassName="max-w-screen-lg"
			ptClassName="pt-7 xl:pt-6"
			pbClassName="pb-6"
			className="shadow bg-gray-95 text-gray-10"
		>
			<div className="items-center justify-center -ml-1 grid gap-3 md:gap-4 grid-flow-col">
				<Icon
					name="notification"
					className="self-start w-7 md:w-8 lg:w-10 text-teal-40 -mt-2px"
				/>
				<HTMLContent
					html={notification.textHTML}
					componentOverrides={{
						p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
					}}
				/>
			</div>
		</BoundedBox>
	)
}
